import React from 'react'
import { Box, Container, Flex, Text, Image } from '@vtex/store-ui'
import AtomIcon from 'src/components/icons/AtomIcon'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import styles from './styles.json'

const LookAgainSection = ({ data }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <Box sx={styles}>
      <Box sx={styles.wrapper}>
        <Container sx={styles.container}>
          <Text as="h3">
            OLHA <span>DE NOVO</span>
          </Text>
          <Text as="h4">
            Tem mais novidades <strong>na família Power Stay!</strong>
          </Text>
          <Text as="p">
            O COMBO PERFEITO PARA QUEM QUER UMA MAKE DE LONGA DURAÇÃO
          </Text>
        </Container>
        <Flex sx={styles.powerStayBase}>
          <Box sx={styles.powerStayBase.image}>
            <Image
              alt={data?.cmsInstitutionalPage?.sections[1].alt}
              title={data?.cmsInstitutionalPage?.sections[1].alt}
              src={
                isMobile
                  ? data?.cmsInstitutionalPage?.sections[1].props.mobile.srcSet
                  : data?.cmsInstitutionalPage?.sections[1].props.desktop.srcSet
              }
            />
          </Box>
          <Box sx={styles.powerStayBase.textWrapper}>
            <Box>
              <Text as="h3">AGORA A BASE COMPACTA 3 EM 1</Text>
              <Text as="h4">É POWER STAY</Text>
            </Box>
            <Box>
              <AtomIcon />
            </Box>
            <Box>
              <Text as="p">
                Sua nova fórmula <strong>Fresh Wear Complex</strong> protege
                contra a água, óleo, suor, calor e poluentes, ao mesmo tempo que
                deixa os poros respirarem, resistindo muito mais!
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default LookAgainSection
