/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
import { useMemo } from 'react'
import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import { Box, jsx } from '@vtex/store-ui'
import imagesConf from 'src/images/config'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'

import styles from './styles.json'

interface Props {
  title: string
  description: string
  alt: string
  desktop: {
    srcSet: string
  }
  mobile: {
    srcSet: string
  }
  heightInline?: [string]
  heightBanner?: { height: number; width: number }
  plLandingPage?: boolean
}

interface BannerImage {
  desktop: {
    srcSet: string
  }
  mobile: {
    srcSet: string
  }
}

const useImages = (bannerImage: BannerImage) => {
  const getThumborImageData = useGetThumborImageData()

  return useMemo(() => {
    const { desktop, mobile } = bannerImage

    const desktopImage = getThumborImageData({
      baseUrl: desktop.srcSet,
      ...imagesConf['searchBanner.desktop'],
    })

    const mobileImage = getThumborImageData({
      baseUrl: mobile.srcSet,
      ...imagesConf['searchBanner.mobile'],
    })

    return withArtDirection(desktopImage, [
      {
        media: '(max-width: 830px)',
        image: mobileImage,
      },
    ])
  }, [bannerImage, getThumborImageData])
}

const PageBanner = ({
  alt,
  desktop,
  mobile,
  heightInline,
  heightBanner,
  plLandingPage,
}: Props) => {
  const bannerImage = { desktop, mobile }
  const image = useImages(bannerImage)

  const pageStyle = plLandingPage ? styles.plLandingPage : styles

  const { width, height, ...rest } = image as any

  const adjustedImage = {
    ...heightBanner,
    ...rest,
  }

  const imageData = !heightBanner ? image : adjustedImage

  return (
    <Box sx={pageStyle.container}>
      <GatsbyImage
        sx={{
          ...pageStyle.image,
          height: heightInline,
        }}
        alt={alt}
        image={imageData}
        loading="eager"
        objectFit="fill"
        title={alt}
      />
    </Box>
  )
}

export default PageBanner
